.header {
    position: relative;
}

.paper-toolbar {
    top: 0;
    left: 3rem;
    position: absolute;
    display: block;
    /* background: lightcoral; */
}

.document-title {
    margin-top: 0.1rem;
    font-size: 1rem;
    border: 0;
    border-radius: 0.25rem;
}

.document-title-container .tooltip {
    font-size: 0.75rem;
    font-weight: bold;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 0.25rem;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1;
    top: 50%;
    left: 50%;
    margin-left: -50%;
}

.document-title-container:hover .tooltip {
    visibility: visible;
}

.document-title:focus {
    outline: 0.1rem solid lightskyblue;
}

.buttons {
    display: block;
}

.buttons>button {
    font-size: 1.4rem;
    border: 0;
    background: transparent;
    color: darkgray;
    vertical-align: middle;
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip {
    font-size: 0.75rem;
    font-weight: bold;
    visibility: hidden;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 0.25rem;
    border-radius: 0.25rem;
    position: absolute;
    z-index: 1;
    top: 75%;
    left: 50%;
    margin-left: -50%;
}

.buttons>button:hover {
    color: lightskyblue;
}

.buttons>button:hover .tooltip {
    visibility: visible;
}

.editor-container {
    font-size: 16px;
    font-size: 1rem;
    min-height: vh;
    overflow: scroll;
    position: fixed;
    bottom: 0;
    top: 3rem;
    left: 0;
    right: 0;
    overflow-y: scroll;
    background: #f1f1f1;
}

.paper {
    background: white;
    border: 1px solid lightgray;
    padding: 1in;
    position: absolute;
    min-height: 11.8in;
    top: 1rem;
    left: 3rem;
    right: 3rem;
}

.paper:focus {
    outline: none;
}

span.heading {
    color: #AB2525;
}

span.sentence {
    color: black;
}

span.uncompleted {
    color: grey;
    overflow-wrap: break-word;
}

span.independent.heading {
    color: #295F94;
    overflow-wrap: break-word;
}

span.uncompleted.bol {
    overflow-wrap: break-word;
}

span.list.order {
    display: inline-block;
    width: 3em;
}

.suggestion-box {
    position: fixed;
    background: #f3f3f3;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    list-style: none;
    margin: 0;
    padding: 0;
    border: 0.1rem solid #ddd;
    max-height: 10em;
    overflow-y: auto;
}

.sugggestion-box>li {
    white-space: pre-wrap;
}

.suggestion-box>li>mark {
    color: #0080FF;
    background: none;
}

.selected-suggestion {
    background: #aaa;
    color: black;
    cursor: pointer;
}

.hovered-suggestion {
    background: #d3d3d3;
    color: black;
    cursor: pointer;
}

.documents-list-container {
    padding: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 16px;
}

.documents-toolbar {
    padding: 0.5rem;
}

.create-document-button {
    position: relative;
    background: lightskyblue;
    color: white;
    border: 0;
    font-size: 1.2rem;
    vertical-align: middle;
    border-radius: 0.5rem;
    padding-left: 2rem;
    cursor: pointer;
}

.create-document-button>.icon {
    position: absolute;
    top: 0.3rem;
    left: 0.2rem;
}

.documents-list-header {
    margin: 0.1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
    background: lightblue;
}

.documents-list {
    display: grid;
}

.document {
    display: grid;
    grid-template-columns: 70% 25% 5%;
    margin: 0.1rem;
    padding: 0.5rem;
    border-radius: 1rem;
    cursor: pointer;
}

.document:hover {
    background: #cce2ff;
}

.document>.name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.document>.last-updated {
    padding-left: 2rem;
}

.document>.controls {
    text-align: right;
    font-size: 1.5rem;
    font-weight: bold;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
}

.document>.controls:hover {
    background: #f1f1f1;
}

.document-menu {
    position: fixed;
    display: block;
    margin-top: 0px;
    border: 1px solid #f1f1f1;
    background: white;
    position: fixed;
    font-size: 0.8rem;
    list-style: none;
    cursor: default;
    border-radius: 0.2em;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    z-index: 1;
}

.document-menu>.option {
    font-size: 0.8rem;
    padding: 0.5rem;
    cursor: pointer;
    background: white;
}

.document-menu>.option:hover {
    background: #f1f1f1;
}

.hide {
    display: none;
}

.top-search-bar {
    position: absolute;
    top: 0.25rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    min-width: 30rem;
    max-width: 50rem;
    height: 1.5rem;
    line-height: 1.5rem;
    border-radius: 0.5rem;
    background: #f1f1f1;
    border: none;
    padding: 0.5rem;
    font-size: 1.5rem;
    /* Need a specific value to work */
}

.top-search-bar:focus {
    outline: none;
}

.logo-text {
    display: inline-block;
    font-size: 1.75rem;
    margin-left: 0.25rem;
    font-weight: bold;
}

.user-bar {
    float: right;
    margin-top: 0.25rem;
    margin-right: 0.25rem;
}

.avatar {
    /* Center the content */
    display: inline-block;
    vertical-align: middle;
    /* Used to position the content */
    position: relative;
    /* Colors */
    background-color: rgba(0, 0, 0, 0.3);
    color: #fff;
    /* Rounded border */
    border-radius: 50%;
    height: 2.5rem;
    width: 2.5rem;
    border: 0;
    font-size: 1rem;
    cursor: pointer;
}

.avatar-letters {
    /* Center the content */
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
}