    body {
        background: white;
    }
    
    nav {
        display: inline-block;
    }
    
    nav>ul {
        list-style: none;
    }
    
    nav>ul>li {
        display: inline-block;
        margin-right: 1rem;
    }
    
    nav>ul>li>a {
        text-decoration: none;
        color: darkgray;
    }
    
    .access {
        float: right;
        margin-top: 1rem;
        margin-right: 0.5rem;
    }
    
    .access>a {
        font-size: 1rem;
        border: 0;
        background: none;
        text-decoration: none;
        color: darkgray;
        margin-left: 1rem;
        padding: 0.25rem;
    }
    
    .access>a.signup {
        background: lightskyblue;
        color: white;
        border-radius: 0.25rem;
    }
    
    .container {}
    
    div.heading {
        font-size: 3rem;
        font-weight: bold;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        text-align: center;
        height: 10rem;
        padding-bottom: 2rem;
    }
    
    div.sub-heading-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    
    div.sub-heading {
        font-size: 1.25rem;
        color: #888;
        max-width: 40rem;
        text-align: center;
    }
    
    div.try-container {
        display: flex;
        align-items: flex-end;
        justify-content: center;
    }
    
    a.try {
        background: lightskyblue;
        font-size: 2rem;
        border: 0;
        color: white;
        padding: 0.5rem 1rem 0.5rem 1rem;
        margin-top: 1rem;
        border-radius: 1rem;
        cursor: pointer;
        text-decoration: none;
    }
    
    .demonstration {
        margin-top: 1rem;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-color: #888;
    }
    
    iframe.demonstration {
        border: 0;
        margin: 1rem;
        border: 1px solid #888;
        height: 40rem;
        width: 90%;
        /* overflow: hidden; */
    }
    
    .demonstration-overlay {
        position: absolute;
        top: 0;
        background: transparent;
        height: 40rem;
        width: 100%;
    }