.header {
    position: fixed;
    width: 100%;
    z-index: 1;
    border-bottom: 1px solid lightgray;
    background: white;
    height: 3rem;
}

h1 {
    margin-left: 0.5rem;
    font-size: 2rem;
    line-height: 1em;
    margin-top: 0.25rem;
    display: inline-block;
}

h1>a {
    border-radius: 0.5rem;
}

h1>a>img {
    height: 2rem;
    vertical-align: middle;
    background: transparent;
    cursor: pointer;
}